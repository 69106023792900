$r3-gen: rgb(52, 196, 185);
$r3-light-grey: rgb(235,235,235);
@use '@angular/material' as mat;

.card-header{
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: white;
}

.hidden {
  display: none;
}

.krucial-container-small{
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;

  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .add-device-card {
    margin-bottom: 7.5rem;
  }

  .mat-mdc-card {
    padding: 1rem;
  }

  .mat-mdc-card-actions {
    justify-content: end;
  }

  .save-button-container {
    justify-content: end;
  }

  ::ng-deep .density-1 {
    @include mat.form-field-density(-1);
  }
}

.krucial-form-medium {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;

  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .add-device-card {
    margin-bottom: 7.5rem;
  }

  .mat-mdc-card {
    padding: 1rem;
  }

  .mat-mdc-card-actions {
    justify-content: end;
  }

  .save-button-container {
    justify-content: end;
  }

  ::ng-deep .density-1 {
    @include mat.form-field-density(-1);
  }
}

.krucial-loading-spinner{
  margin-top: 2.5rem;
  margin-right: auto;
  margin-left: auto;
}

.krucial-card{
  height: 3rem;
  display: flex;
  flex-direction: row;
  vertical-align: center;
  justify-content: space-between;
}

.krucial-page-centred-card {
  margin-left: auto;
  margin-right: auto;
  max-width: 37.5rem;

  &.small {
    max-width: 28.125rem;
  }
}

mat-form-field {
  width: 100%;
}

.center-title{
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  margin: 0;
  vertical-align: center !important;
}

.krucial-mat-icon-inline {
  padding-right: 2rem;
  padding-top: 0.15rem;

  &.rtl {
    padding-right: 0;
    padding-left: 1.25rem;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.krucial-mat-card-fill {
  height: 100%;
  box-sizing: border-box;
}

.krucial-location-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;

  --fish-body: white;

  &.hide-nets {
    --net-color: none;
  }
  &.hide-fish {
    --fish-color: none;
    --fish-body: none;
  }

  &.fill-white {
    fill: #d6d6d6;
  }

  &.fill-red {
    fill: var(--warn-color);
  }

  &.fill-primary {
    fill: var(--primary-color);
  }

  &.fill-greyed-out {
    fill: var(--accent-color);
  }

  &.clickable {
    cursor: pointer;
  }
}

.draggable {
  cursor: move;
}

.small-icon {
  width: 1.5rem;
  height: 1.5rem;

  &.fill-white {
    fill: #d6d6d6;
  }
}

.krucial-small-modal-container {
  width: 11.25rem;

  .mat-mdc-dialog-title {
    text-align: center;
  }

  .mat-mdc-dialog-content {
    padding: 0 3rem 0 3rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .mat-mdc-dialog-actions {
    display: flex;
    button {
      flex: 25%;
    }
  }
}

.krucial-large-modal-container {
  width: 30rem;

  @media(max-width: 24rem) {
    width: 22.5rem;
  }

  .mat-mdc-dialog-title {
    text-align: center;
  }

  .mat-mdc-dialog-content {
    margin: 0;
    padding: 2rem 0;
    overflow-x: hidden;
  }

  .mat-mdc-dialog-actions {
    display: flex;

    button {
      flex: 25%;
    }
  }

  .krucial-modal-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media(max-width: 28rem) {
    width: 100vh;
    padding: 1rem 1rem 1rem 1rem;

    .mat-mdc-dialog-content {
      margin: 0;
      padding: 1rem 0;
    }
  }
}

.krucial-extra-large-modal-container {
  width: 40rem;

  @media(max-width: 24rem) {
    width: 22.5rem;
  }

  .mat-mdc-dialog-title {
    text-align: center;
  }

  .mat-mdc-dialog-content {
    margin: 0;
    padding: 2rem 0;
    overflow-x: hidden;
  }

  .mat-mdc-dialog-actions {
    display: flex;

    button {
      flex: 25%;
    }
  }

  .krucial-modal-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media(max-width: 28rem) {
    width: 100vh;
    padding: 1rem 1rem 1rem 1rem;

    .mat-mdc-dialog-content {
      margin: 0;
      padding: 1rem 0;
    }
  }
}

.krucial-space-between-title-value {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
}

.krucial-space-between-title-value-tall {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 2rem;
}

.krucial-loading-spinner-container {
  .spinner {
    margin-top: 2.5rem;
    margin-right: auto;
    margin-left: auto;
  }
}

.krucial-table-filter {
  width: 100%;
}

.krucial-add-button{
  display: flex;
  justify-content: flex-end;
}

.modal-button{
  width: 5rem;
  margin-right: 0.5rem;
}

.center-row{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.center-div {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 1.5rem;
}

.center-text{
  text-align: center;
}
